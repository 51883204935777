<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">进销存</el-breadcrumb-item>
        <el-breadcrumb-item>出库管理</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="querys" ref="querys" label-width="90px" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col style="width: 140px">
            <el-input v-model="querys.keyword" @keyup.enter="fetchs" prefix-icon="Search"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="所属公司">
          <el-col>
            <!-- <el-cascader v-model="companyId" :options="companys" clearable @change="fetchs" /> -->
            <el-tree-select ref="companys" v-model="companyId" :data="companys" @change="fetchs();" clearable
              check-strictly :render-after-expand="false" style="width:210px" />
          </el-col>
        </el-form-item>

        <el-form-item label="单据时间">
          <el-col>
            <el-date-picker v-model="dateRange" type="daterange" start-placeholder="开始时间" end-placeholder="结束时间"
              unlink-panels :shortcuts="$util.getShortCuts()" @change="fetchs()" style="max-width:210px;">
            </el-date-picker>
          </el-col>
        </el-form-item>

      </el-row>
      <el-row>
        <el-form-item label="操作类型" prop="billType">
          <el-col>
            <el-select v-model="billType" placeholder="请选择操作类型" clearable @change="fetchs" style="width: 150px">
              <el-option v-for="item in dict.billTypes" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="Search" @click="fetchs()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <!-- <el-button type="primary" icon="Plus" @click="inDialogVisible = true">
        入库
      </el-button> -->
      <el-button type="primary" icon="Minus" @click="outDialogVisible = true">
        操作出库
      </el-button>
      <el-button type="primary" icon="Sort" @click="transferDialogVisible = true" style="display: none;">
        渠道货品调拨
      </el-button>
      <el-button plain type="success" icon="Sort" @click="realerDialogVisible = true">
        货品调拨
      </el-button>
      <el-button plain icon="Download" @click="exportXls">
        导出单据
      </el-button>
    </div>
    <!-- 入库弹窗组件 -->
    <div class="xlsdialog">
      <el-dialog title="产品入库" v-model="inDialogVisible" :show-close="true" :close-on-click-modal="false"
        destroy-on-close top="5vh" @closed="inDialogWidth('50%')" :width="dialogWidth" center>
        <instore @In-Dialog-width="inDialogWidth"></instore>
      </el-dialog>
    </div>

    <!-- 出库弹窗组件 -->
    <div class="xlsdialog">
      <el-dialog title="产品出库" v-model="outDialogVisible" :show-close="true" :close-on-click-modal="false"
        destroy-on-close top="5vh" @closed="outDialogWidth('50%')" :width="dialogWidth" center>
        <outstore @out-Dialog-width="outDialogWidth"></outstore>
      </el-dialog>
    </div>

    <!-- 渠道调拨弹窗组件 -->
    <div class="xlsdialog">
      <el-dialog title="渠道库存调拨" v-model="transferDialogVisible" :show-close="true" :close-on-click-modal="false"
        destroy-on-close top="5vh" @closed="transferDialogVisible = false" width="80%" center>
        <transfer @dialog-event="dialogEvent"></transfer>
      </el-dialog>
    </div>

    <!-- 实际库存调拨 -->
    <div class="xlsdialog">
      <el-dialog title="库存调拨" v-model="realerDialogVisible" :show-close="true" :close-on-click-modal="false"
        destroy-on-close top="5vh" @closed="realerDialogVisible = false" width="80%" center>
        <realer @dialog-event="dialogEvent"></realer>
      </el-dialog>
    </div>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
      :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler" :seleHandler="seleHandler">

      <template v-slot:toolbar="scope">
        <el-button @click="watch(scope.row)" type="primary" text size="small">
          [查看]
        </el-button>
      </template>
    </static-table>
  </el-card>
</template>

<script>
import { fetchBills, exportBill } from "@/api/stock";
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
import instore from "@/views/stock/bills/instore.vue";
import outstore from "@/views/stock/bills/outstore.vue";
import transfer from "@/views/stock/bills/transfer.vue";
import realer from "@/views/stock/bills/realer.vue";
export default {
  name: "billIndex",
  components: { StaticDialog, StaticTable, instore, outstore, transfer, realer },
  data() {
    return {
      dateRange: [],

      querys: {
        keyword: "",
        filters: [],
        columns: [
          { type: "selection", width: "55" },
          { type: "index", label: "序号" },
          { prop: "billType", label: "单据类型", sortable: true, searchable: true, width: "110", showOverflowTooltip: true, },
          { prop: "billCode", label: "单据编号", sortable: true, searchable: true, width: "130", showOverflowTooltip: true, },
          { prop: "storeCode", label: "库房", sortable: true, searchable: true, width: "80", showOverflowTooltip: true, },
          { prop: "netCode", label: "关联单号", sortable: true, searchable: true, width: "110", showOverflowTooltip: true, },
          { prop: "productNumber", label: "数量", sortable: true, searchable: true, width: "70", },
          { prop: "costTotal", label: "金额", sortable: true, width: "90", },
          { prop: "billDate", label: "单据时间", width: "140", sortable: true, showOverflowTooltip: true, },
          { prop: "operatorName", label: "操作", sortable: true, searchable: true, width: "70", },
          { prop: "handoverName", label: "交接", sortable: true, searchable: true, width: "70", },
          { prop: "reviewerName", label: "复核", sortable: true, searchable: true, width: "70", },
          { prop: "financerName", label: "财务", sortable: true, searchable: true, width: "70", },

          { prop: "createDate", label: "创建时间", width: "150", sortable: true, showOverflowTooltip: true },
          { prop: "createUserName", label: "创建人", sortable: true, searchable: true, width: "100", },
          { type: "toolbar", label: "操作" },
        ],
      },
      pager: {
        sidx: "billDate", //默认的排序字段
        sord: "descending",
      },
      selects: [],
      currRow: null,
      inDialogVisible: false,
      outDialogVisible: false,
      transferDialogVisible: false,
      realerDialogVisible: false,
      dialogWidth: "50%",

      companys: [],
      companyId: '',

      billType: '销售出库',
      dict: {
        billTypes: [],
      },
    };
  },
  mounted() {
    this.$kaung.dictionary("stock", "outbound").then((res) => {
      this.dict.billTypes = res;
      this.dict.billTypes.push({ value: '库存调拨', label: '库存调拨' })
    });

    this.$kaung.companys().then((res) => {
      this.companys = res;
      this.fetchs();
    });
  },
  methods: {
    watch(row) {
      this.$router.push({ path: "detail", query: { billCode: row.billCode } });
    },

    fetchs() {
      this.querys.filters = [];
      if (this.dateRange && this.dateRange[0])
        this.querys.filters.push({
          name: "billDate",
          value: this.$util.fmtDate(this.dateRange[0], "yyyy-MM-dd"),
          operate: 2,
        });
      if (this.dateRange && this.dateRange[1])
        this.querys.filters.push({
          name: "billDate",
          value: this.$util.fmtDate(this.dateRange[1], "yyyy-MM-dd") + " 23:59:59",
          operate: 4,
        });

      if (this.companyId && this.companyId.length > 0) {
        this.querys.filters.push({
          name: "companyId",
          value: this.companyId,
          operate: 6,
        });
      }

      if (this.billType) {
        this.querys.filters.push({
          name: "billType",
          value: this.billType,
          operate: 0,
        });
      }
      else {
        let billTypes=[];
        this.dict.billTypes.forEach((res)=>{billTypes.push(res.value)});
        this.querys.filters.push({
          name: "billType",
          value: billTypes.join(','),
          operate: 6,
        });
      }


      //移除多余的参数
      //let { data, records, total, ...query } = { ...this.pager, ...params };
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };

      fetchBills(search).then((res) => {
        this.pager = { ...res.data };
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
    seleHandler(rows) {
      this.selects = rows;
    },
    //控制dialog宽度与子组件控制关闭弹窗
    inDialogWidth(data) {
      if (data === "80%" || data === "50%") {
        this.dialogWidth = data;
      } else {
        this.inDialogVisible = false;
        this.fetchs();
      }
    },
    outDialogWidth(data) {
      if (data === "80%" || data === "50%") {
        this.dialogWidth = data;
      } else {
        this.outDialogVisible = false;
        this.fetchs();
      }
    },
    dialogEvent(data) {
      if (data === 'realerDialogVisible') {
        this.realerDialogVisible = false;
      } else if (data === 'transferDialogVisible') {
        this.transferDialogVisible = false;
      }
      this.fetchs();
    },
    exportXls() {
      if (this.selects.length == 0) {
        this.$message({ message: '请勾选至少一个订单' });
        return;
      }
      this.selects.forEach(element => {
        exportBill(element.billCode).then((res) => {
          if (res.code == 200)
            window.location = process.env.VUE_APP_BASEURL + res.data;
        });
      });
      // if (this.currRow) {
      //   exportBill(this.currRow.billCode).then((res) => {
      //     if (res.code == 200)
      //       window.open(process.env.VUE_APP_BASEURL + res.data);
      //   });
      // }
    }
  },
  watch: {
    companyId: function (newValue, oldValue) {
      console.log('watch companyId', newValue);
      if (newValue && newValue != oldValue)
        this.$util.setLocalstorage('companyIdStr', newValue);
      else
        this.$util.setLocalstorage('companyIdStr', '');
    },
    billType: function (newValue, oldValue) {
      if (newValue != oldValue)
        this.$util.setPageCache('billType', newValue);
    },
    status: function (newValue, oldValue) {
      if (newValue != oldValue)
        this.$util.setPageCache('status', newValue);
    },
    pager: function (newValue, oldValue) {
      this.$util.setPageCache('keyword', newValue.keyword);
      if (newValue.page)
        this.$util.setPageCache('page', newValue.page);
      if (newValue.rows)
        this.$util.setPageCache('rows', newValue.rows);
      if (newValue.sidx)
        this.$util.setPageCache('sidx', newValue.sidx);
      if (newValue.sord)
        this.$util.setPageCache('sord', newValue.sord);
    },
    dateRange: function (newValue, oldValue) {
      console.log(newValue);
      if (newValue && newValue != oldValue)
        this.$util.setLocalstorage('dateRangeStr', newValue.join(','));
      else
        this.$util.setLocalstorage('dateRangeStr', ',');
    },
  },
  created: function () {
    let companyIdStr = this.$util.getLocalstorage('companyIdStr');
    if (companyIdStr && companyIdStr.length > 0) {
      this.companyId = companyIdStr;
    }
    this.billType = this.$util.getPageCache('billType') || "";
    this.status = this.$util.getPageCache('status');

    this.querys.keyword = this.$util.getPageCache('keyword');

    this.pager.page = parseInt(this.$util.getPageCache('page') || 1);
    this.pager.rows = parseInt(this.$util.getPageCache('rows') || 10);

    let sidx = this.$util.getPageCache('sidx');
    if (sidx) this.pager.sidx = sidx;
    let sord = this.$util.getPageCache('sord');
    if (sord) this.pager.sord = sord;

    let dateRangeStr = this.$util.getLocalstorage('dateRangeStr');
    if (dateRangeStr != '' && dateRangeStr.split(',').length == 2) {
      this.dateRange = dateRangeStr.split(',');
    }
  }
};
</script>

<style lang="less">
.xlsdialog {
  .el-dialog__body {
    max-height: 80vh;
    overflow-y: auto;
  }
}

.el-form .el-date-editor {
  width: 220px;
}
</style>>
